import Vue from "vue";


async function sendEmail(fromID, fromName, toID, emailSubject, emailText){

  let html = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Welcome Card</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
            background-color: #f7f7f7;
        }

        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            width: 40%;
            min-width: 300px;
            border-radius: 5px;
            background-color: white;
            padding: 2em;
            text-align: center;
        }

        .card:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }

        .welcome-text {
            color: #333;
            font-size: 24px;
            margin-top: 0;
        }
    </style>
</head>
<body>

<div class="card">
  <p class="welcome-text"> ${emailText} </p>
 
</div>

</body>
</html>
`;

console.log(emailText);
let from = fromName+"<"+fromID+">";
  Vue.prototype.$db.collection('mail').add({
    
    from: from,
    to: toID,
    message: {
     subject: emailSubject,
     html: html,
   },
   
 });
 alert("Email Sent");
}

export async function sendFeedback( userEmailID, userName, feedbackEmailID, feedbackSubject, feedbackText){
    return sendEmail(userEmailID, userName, feedbackEmailID, feedbackSubject, feedbackText);
}

export async function sendComplaint( fromID, fromName, toID, emailSubject, emailText){
  return sendEmail(fromID, fromName, toID, emailSubject, emailText);
}

export async function sendAlert( fromID, fromName, toID, emailSubject, emailText){
  return sendEmail(fromID, fromName, toID, emailSubject, emailText);
}
export async function sendRisk( fromID, fromName, toID, emailSubject, emailText){
  return sendEmail(fromID, fromName, toID, emailSubject, emailText);
}

export async function sendNote( fromID, fromName, toID, emailSubject, emailText){
  return sendEmail(fromID, fromName, toID, emailSubject, emailText);
}