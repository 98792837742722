<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-stepper v-model="e1">
        <v-stepper-header non-linear style="height:72px;">
          <v-stepper-step :complete="e1 > 1" step="1" editable>About Me</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2" editable>About the complainant</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3" editable>Assistance</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 4" step="4" editable>Your complaint</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 5" step="5" editable>Who is your complaint about?</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="6" editable>Further information</v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-text>
                <v-col cols="12">
                  <v-text-field v-model="vm.fullName" label="Full Name" required></v-text-field>
                  <v-text-field v-model="vm.ndisNumber" label="NDIS Number (if available):"></v-text-field>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="e1 = 2" type="submit">Continue</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card>
              <v-card-text>
                <v-col cols="12">
                  <v-radio-group
                    v-model="vm.onBehalf"
                    label="Are you filling this in on behalf of someone else?"
                  >
                    <v-radio label="No" v-bind:value="false"></v-radio>
                    <v-radio label="Yes" v-bind:value="true"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-card-text>
                  <v-col cols="12" v-if="vm.onBehalf == true">
                    <v-text-field v-model="vm.nameOfPerson" label="Name of person"></v-text-field>
                    <v-text-field
                      v-model="vm.relationship"
                      label="What is your relationship to that person?"
                    ></v-text-field>
                    <v-radio-group v-model="vm.personKnowsYouAreMakingComplaint">
                      Does the person know you are making this complaint?
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <v-radio-group v-model="vm.personConsentsToComplaintBeingMade">
                      Does the person consent to the complaint being made?
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="e1 = 1">back</v-btn>
                  <v-btn color="primary" @click="e1 = 3" type="submit">Continue</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card>
              <v-card-text>
                <v-col cols="12">
                  <v-radio-group
                    v-model="vm.requireAssistanceFillingOutForm"
                    label="Do you need assistance filling out this ?"
                  >
                    <v-radio label="No" v-bind:value="false"></v-radio>
                    <v-radio label="Yes" v-bind:value="true"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="6" v-if="vm.requireAssistanceFillingOutForm">
                  <v-text-field
                    class="ml-8"
                    v-model="vm.representativeName"
                    label="Name of representative"
                  ></v-text-field>
                  <v-text-field
                    class="ml-8"
                    v-model="vm.representativeOrganisation"
                    label="Organisation"
                  ></v-text-field>

                  <address-picker
                    :detail-mode="false"
                    label="Address"
                    :min-characters="4"
                    v-model="vm.representativeAddress"
                    required
                    vvalidate="'required'"
                    data-vv-as="Representative address"
                    name="representativeAddress"
                    :error-messages="errors.collect('representativeAddress')"
                  ></address-picker>

                  <v-text-field
                    prepend-icon="mdi-phone"
                    v-model="vm.representativePhone"
                    label="Phone"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-cellphone-basic"
                    v-model="vm.representativeMobile"
                    label="Mobile"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-email"
                    v-model="vm.representativeEmail"
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="e1 = 2">back</v-btn>
                <v-btn color="primary" type="submit" @click="e1 = 4">Continue</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card>
              <v-card-text>
                <v-col cols="12">
                  <h4>What is your complaint about?</h4>
                  <h6>Provide some details to help us understand your concerns. You can include what happened, where it happened and who was involved or the decision made by the Agency that you are unhappy about</h6>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    filled
                    v-model="vm.complaintDescription"
                    label="Complaint description"
                  ></v-textarea>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="e1 = 3">back</v-btn>
                <v-btn color="primary" @click="e1 = 5" type="submit">Continue</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h4>Who is your complaint about?</h4>
                  <h6>Name of the person, or service about whom you are complaining (the respondent or the Agency person who made the decision)</h6>
                </v-col>
                <v-col class="pr-4" cols="6">
                  <v-text-field v-model="vm.nameOfComplaintAgainst" label="Name/Organisation"></v-text-field>

                  <address-picker
                    :detail-mode="false"
                    label="Address"
                    :min-characters="4"
                    v-model="vm.complaintOrgAddress"
                    required
                    vvalidate="'required'"
                    data-vv-as="Complaint Org address"
                    name="complaintOrgAddress"
                    :error-messages="errors.collect('complaintOrgAddress')"
                  ></address-picker>
                  <v-text-field
                    v-model="vm.complaintOrgRelationship"
                    label="What is the person's/organisation's relationship to you"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    prepend-icon="mdi-phone"
                    v-model="vm.phoneOfComplaintAgainst"
                    label="Phone"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-cellphone-basic"
                    v-model="vm.mobileOfComplaintAgainst"
                    label="Mobile"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-email"
                    v-model="vm.emailOfComplaintAgainst"
                    label="Email"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    name="outcomesSeeking"
                    v-model="vm.outcomesSeeking"
                    filled
                    label="What outcomes are you seeking? Provide details here"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="e1 = 4">back</v-btn>
                <v-btn color="primary" @click="e1 = 6" type="submit">Continue</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-stepper-content>

          <v-stepper-content step="6">
            <v-card>
              <v-card-text>
                <h4>Please attach copies of any documents that may help us investigate your complaint</h4>

                <upload
                  v-bind:path="basePath"
                  buttonText="Upload"
                  :text="false"
                  v-on:uploadComplete="documentUploaded"
                ></upload>
                <v-col cols="12">
                  <v-list>
                    <v-list-item v-for="(doc,index) in vm.documents" :key="index">
                      <v-list-item-content>
                        <a v-bind:href="doc.downloadURL" target="_blank">{{doc.filename}}</a>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-textarea
                  name="whatWeShouldObtain"
                  v-model="vm.whatWeShouldObtain"
                  filled
                  label="If you cannot upload supporting documents, please tell us what you think we should obtain"
                ></v-textarea>

                <h4>Have you made a complaint about this to another agency?</h4>
                <h6>for example: a disability service or equal opportunity agency, Health Care Complaints Commision, Ombudsman</h6>
                <h6>If so please provide details of the agency to which you made your complaint and any outcome. Please also attach copies of any letters you have received from that agency.</h6>
                <v-textarea
                  name="complaintMadeToAnotherAgency"
                  v-model="vm.complaintMadeToAnotherAgency"
                  filled
                  label="enter details here"
                ></v-textarea>

                <v-col>
                  <v-checkbox
                    v-model="vm.consentToNDISProvidingInfo"
                    label="Please check this box to consent to the National Diability Insurance Agency providing information to a third party (e.g. A Provider or another jurisdiction) to resolve your issue"
                  ></v-checkbox>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="e1 = 5">back</v-btn>
                <v-btn color="primary" @click="e1 = 7" type="submit">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
  </v-container>
</template>


<script>
import AddressPicker from "../../components/core/AddressPicker";
import * as firebaseEmailService from "../../services/firebaseEmailService";
import * as notificationService from "../../services/notificationService";
export default {
  name: "feedback-form",
  components: {
    AddressPicker
  },
  data: () => ({
    e1: 0,
    vm: {
      onBehalf: false,
      requireAssistance: false
    },
    emails: [],
    form: {},
    basePath: "",
    headers: [
      { text: "Type", value: "type", width: "1%" },
      { text: "Detail", value: "detail", width: "1%" }
    ],
    contactType: ["Phone", "Mobile", "Fax", "TTY", "Email"],
    showDialog: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentComplaint() {
      return this.$store.getters.currentComplaint;
    }
  },
  methods: {
    async init() {
      this.basePath = this.currentOrganisation.id + "/complaints/";
      this.vm = {};
      if (
        this.currentComplaint !== undefined &&
        this.currentComplaint !== null
      ) {
        this.vm = this.currentComplaint;
      }

      await notificationService.getCompalintById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.emails = temp.Emails;
        console.log(this.emails);
      });
    
    },
    documentUploaded(data) {
      if (this.vm.documents == undefined || this.vm.documents == null) {
        this.vm.documents = [];
      }
      this.vm.documents.push(data);
      this.$store.dispatch("setCurrentComplaint", this.vm);
      this.$forceUpdate();
    },
    validateBeforeSubmit() {
      const me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch("setCurrentComplaint", this.vm);
          if (me.e1 == 7) {
            me.$router.push("/feedback/complaints-overview");
          }
             let fromID=me.user.email;
             let fromName=me.user.displayName;
             let toID=this.emails;
             console.log(toID);
             let subject="Omar notifications - new complaint";
             let text=me.vm.fullName +" raised complaint on service";
             let sendEmail="Do you want to send an Email?";
             
             if (confirm(sendEmail) == true) {
                    firebaseEmailService.sendComplaint(fromID, fromName, toID, subject, text);
                
              } else {
                  console.log("not sent");
              }
            
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    addContact() {
      this.showDialog = true;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

